body {
  background-image: url("./856f31d9f475501c7552c97dbe727319.jpg");
}

h1 {
  /* background-color: #dd5c5c ; */
  background-color: yellow;
  width: 500px;
  margin: 0 auto;
}
h2 {
  text-align: left;
  width: 800px;
  margin: 0 auto; 
}

.App {
  text-align: center;
}

.initial h1{
  font-weight: 900;
  letter-spacing: 2px;
}
.initial h2{
  font-weight: 500;
  font-family:  Times, serif;
   font-size: 25px;
   letter-spacing: 1px;
}
.data {
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgba(255, 238, 0, 0.8);
  font-size: 30px;
  font-weight: 500; 
  width: 850px;
  height: 450px;
  margin: 0 auto;
  padding-top: 70px;
  padding-bottom: 0px;
  border-radius: 10px;
  position: relative;
  right: 10px;
}
.data form h2 {
  text-align: center;
  width: 250px;
  font-size: 30px;
  position: relative;
  bottom: 50px;
  right: 19px;
  color: black;
  font-family:  Arial, Helvetica, sans-serif;
  margin: 0 auto;
}
.data form h3 {
  text-align: center;
  position: relative;
  right: 12px;
  width: 250px;
  font-size: 30px;
  color: black;
  font-family:  Arial, Helvetica, sans-serif;
}

.data form {
  display: inline-block;
  height: 170px;
  width: 200px;
  position: relative;
  bottom: 45px;
  margin: 0 auto;
}
.data #metric {
  position: relative;
  bottom: 50px;
}
.data form input {
  height: 25px;
  width: 50px;
  padding-left: 10px;
} 
.data #standard label {
   margin:10px;
}

.data #standard input {
  margin: 10px;
}
.data #standard #inch {
  position: relative;
  left: 10px;
  margin-left: 20px;
}
.button {
  width: 115px;
  padding: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 20px;
}
#standard {
   position: relative;
   left: 50px; 
   
}
#metric {
  position: relative;
  bottom: 100px;
  right: 50px;
  
}
#bodymass {
  margin-top: 50px;
  font-weight: bold;
  font-size: 40px;
}
.results p {
  
  width: 370px;
  margin: 0 auto;
}

.resultsImg img{
  height: 300px;
  border-radius: 20px;
}
.weight {
  margin-top: 50px;
}

.weight p {
  margin-bottom: 50px;
  font-size: 40px;
  width: 300px;
  font-weight: 700;
  background-color: yellow;
}

.results button {
  font-size: 30px;
  margin: 30px;
  height: 75px;
  width: 200px;
}
@media only screen and (min-width: 250px) and (max-width:480px), (orientation: portrait) {
  
  .h1,h2 {
    position: relative;
    left: 65px;
    width:380px;
    
  }
  .data {
    width: 420px;
   position: relative;
   left: 30px;
  }
  .data form h3,.data form h2 {
    font-size: 25px;
    position: relative;
    right: 20px;
    
  }

  .data form h2 {
    position: relative;
    left: 0px;
  }

  #standard {
    position: relative;
    left: 20px;   
  }
    #metric {
    position: relative;
    right: 20px;
  }

  .resultsImg img{
    height: 125px;
    border-radius: 20px;
  }
  .weight {
    position: relative;
    left:20px
  }
  .results p {
    width: 350px;
    margin: 0 auto;
    position: relative;
    left: 20px;
  }
  .results button {
    font-size: 30px;
    margin: 30px;
    height: 75px;
    width: 150px;
    text-align: center;
    position: relative;
    left: 25px;
    

  }
    
  
}